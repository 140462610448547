import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import * as inlineStyles from "../components/Layout/inlineStyles"

const Sok = () => (
  <Layout>
    <SEO title="Sök" />
    <div className="container" style={inlineStyles.container}>
      <h1>Sök</h1>
    </div>
  </Layout>
)

export default Sok
